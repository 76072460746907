/* In your CSS file or in a style block within your component */
.message-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent overlay */
}
.message-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d0d0d0;
    width: 40vw;
    /* height: 150px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.message-content {
    color: #000000;
    /* background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}
