.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.sidebar {
    height: 100%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* App.css */
.app {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 250px; /* Set the width of the sidebar as needed */
    flex: 0 0 auto; /* Don't allow the sidebar to grow or shrink */
    position: fixed; /* Fix the position of the sidebar */
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #333; /* Set the background color of the sidebar */
    overflow-y: auto; /* Allow the sidebar to scroll if content overflows */
}

.content {
    flex: 1; /* Allow the main content to grow to fill available space */
    overflow-y: auto; /* Allow the main content to scroll if content overflows */
    padding: 16px; /* Add padding as needed */
}
