.cmtSecondRow {
    width: 100%;
    margin-top: 15px;
    /* max-width: 800px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.cmtSheetName {
    display: flex;
    align-items: center;
}

.cmtClientSheetName {
    position: absolute;
    right: 20px;
}

.cmtClientSheetName h4 {
    margin-right: 10px;
}

.cmtClientSheetNameTextField {
    margin-right: '30px';
}

.sheetNameDropDown {
    min-width: 200px;
    margin-left: 10px;
}

.clientSheetNameDropDown {
    min-width: 200px;
    max-width: 200px;
}

hr {
    margin: 10px 0;
}
.grid-table-header {
    /* border: 1px solid gray; */
    /* padding-bottom: 10px; */
    /* margin: 0; */
    /* border-bottom: 0; */
    /* background-color: #e4e4e4; */
    /* border-radius: 3px; */
}
.grid-table-container {
    background-color: inherit;
    /* margin: 0; */
    padding: 1.5px 0;
    /* padding-bottom: 3px; */
    /* border: 1px solid gray; */
    /* padding-bottom: 20px; */
    /* margin: 0; */
}
